import { Component, OnInit } from '@angular/core';
import { EventdataService } from '../../services/eventdata.service';
import { IEvent } from '../../models/eventdata';
import { Router } from '@angular/router';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  constructor(private _eventservice: EventdataService, public router: Router) { }
  fablabEventCards: IEvent[] = [];
  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];
  filteredEventCards: IEvent[] = [];
  ngOnInit(): void {
    // this._eventservice.GetFabLabEvents().then((res: IEvent[]) => {
    this._eventservice.getTheEvents().then((res: IEvent[]) => {
      this.fablabEventCards = res
        // .filter(event => event.section_Student_fab === true && event.published === true && event.isDeleted === false)
        .filter(event => event.section_Student_fab === true && event.published === true && event.isDeleted === false)
        .map(event => {
          // Setting the Event Card Images
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          // Replacing the Commas with Bullet Points in the Event Card
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.showcasedCards = this.fablabEventCards.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.fablabEventCards.filter(event => event.isShowcased === false);
      // console.log(this.fablabEventCards);
      // console.log(this.showcasedCards);
      // console.log(this.nonshowcasedCards);
      this.filteredEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
    });
  }
  toEvent(eventCard: IEvent): void {
    if (eventCard.internal_event === true) {
      this.router.navigate(['/Event', eventCard.eventId]);
    } else if (eventCard.internal_event === false) {
      window.open(eventCard.description_Long, "_blank");
    }
  }
}