import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit {
  hideK2Text = false;
  hide35Text = false;
  hideMidText = false;
  hideHighText = false;
  constructor() { }
  toggleK2Display() {
    if (this.hideK2Text == false){
      this.hideK2Text = true;
      let cardK = document.getElementById('cardK')!;
      cardK.classList.remove('fa-chevron-up');
      cardK.classList.add('fa-chevron-down');
    } else if (this.hideK2Text == true){
      this.hideK2Text = false;
      let cardK = document.getElementById('cardK')!;
      cardK.classList.remove('fa-chevron-down');
      cardK.classList.add('fa-chevron-up');
    }
  }
  toggle35Display() {
    if (this.hide35Text == false){
      this.hide35Text = true;
      let card35 = document.getElementById('card35')!;
      card35.classList.remove('fa-chevron-up');
      card35.classList.add('fa-chevron-down');
    } else if (this.hide35Text == true){
      this.hide35Text = false;
      let card35 = document.getElementById('card35')!;
      card35.classList.remove('fa-chevron-down');
      card35.classList.add('fa-chevron-up');
    }
  }
  toggleMidDisplay() {
    if (this.hideMidText == false){
      this.hideMidText = true;
      let cardM = document.getElementById('cardM')!;
      cardM.classList.remove('fa-chevron-up');
      cardM.classList.add('fa-chevron-down');
    } else if (this.hideMidText == true){
      this.hideMidText = false;
      let cardM = document.getElementById('cardM')!;
      cardM.classList.remove('fa-chevron-down');
      cardM.classList.add('fa-chevron-up');
    }
  }
  toggleHighDisplay() {
    if (this.hideHighText == false){
      this.hideHighText = true;
      let cardH = document.getElementById('cardH')!;
      cardH.classList.remove('fa-chevron-up');
      cardH.classList.add('fa-chevron-down');
    } else if (this.hideHighText == true){
      this.hideHighText = false;
      let cardH = document.getElementById('cardH')!;
      cardH.classList.remove('fa-chevron-down');
      cardH.classList.add('fa-chevron-up');
    }
  }
  ngOnInit(): void {
  }
}
