import { Component, OnInit } from '@angular/core';
import { SmoothScrollService, ISmoothScrollOption } from '@boatzako/ngx-smooth-scroll';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'fablab';

  public isMenuCollapsed = true;
  btnClicked: boolean = true;

  constructor(public router:Router, private smooth: SmoothScrollService) { }

  ngOnInit(): void {
    this.smooth.smoothScrollToAnchor();
  }
}
