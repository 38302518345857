import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { FablabComponent } from './pages/fablab/fablab.component';
import { EventComponent } from './pages/event/event.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
        FablabComponent,
        EventComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModule
    ],
    exports: [
        FablabComponent,
        EventComponent
    ],
})
export class ViewModule { }
