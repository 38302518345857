import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { BannerComponent } from './components/banner/banner.component';
import { EquipmentComponent } from './components/equipment/equipment.component';
import { ExperienceComponent } from './components/experience/experience.component';
import { LessonsComponent } from './components/lessons/lessons.component';
import { LocationComponent } from './components/location/location.component';
import { FooterComponent } from './components/footer/footer.component';
import { EventsComponent } from './components/events/events.component';
import { RouterModule } from '@angular/router';
import { FieldtripsComponent } from './components/fieldtrips/fieldtrips.component';
import { SummercampComponent } from './components/summercamp/summercamp.component';


@NgModule({
    declarations: [
        BannerComponent,
        EquipmentComponent,
        ExperienceComponent,
        LessonsComponent,
        LocationComponent,
        FooterComponent,
        EventsComponent,
        FieldtripsComponent,
        SummercampComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule
    ],
    exports: [
        RouterModule,
        BannerComponent,
        EquipmentComponent,
        ExperienceComponent,
        LessonsComponent,
        LocationComponent,
        FooterComponent,
        EventsComponent,
        FieldtripsComponent,
        SummercampComponent
    ],
})
export class SharedModule { }
