import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventdataService } from '../../services/eventdata.service';
import { IEvent } from '../../models/eventdata';

@Component({
  selector: 'app-fieldtrips',
  templateUrl: './fieldtrips.component.html',
  styleUrls: ['./fieldtrips.component.scss']
})
export class FieldtripsComponent implements OnInit {
  fieldtripsEventCards: IEvent[] = [];
  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];
  filteredEventCards: IEvent[] = [];

  constructor(private _eventservice: EventdataService, public router: Router) { }

  ngOnInit(): void {
    // this._eventservice.getFabLabFieldTripEvents().then((res: IEvent[]) => {
    this._eventservice.getTheEvents().then((res: IEvent[]) => {
        this.fieldtripsEventCards = res
        .filter(event => event.published === true && event.section_Fab_trip === true && event.isDeleted === false && event.section_Student_durham === false)
        // .filter(event => event.published === true)
        .map(event => {
          // Setting the Event Card Images
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          // Replacing the Commas with Bullet Points in the Event Card
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.showcasedCards = this.fieldtripsEventCards.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.fieldtripsEventCards.filter(event => event.isShowcased === false);
      this.filteredEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
      // console.log(this.fieldtripsEventCards)
    });
  }
  toEvent(eventCard: IEvent): void {
    if (eventCard.internal_event === true) {
      this.router.navigate(['/Event', eventCard.eventId]);
    } else if (eventCard.internal_event === false) {
      // window.location.href = eventCard.description_Long;
      window.open(eventCard.description_Long, "_blank")
    }
  }
}
