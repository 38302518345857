import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventComponent } from './view/pages/event/event.component';
import { FablabComponent } from './view/pages/fablab/fablab.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', component: FablabComponent},
  {path: 'Event/:typeId', component: EventComponent},
  {path: '**', redirectTo: ''},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
