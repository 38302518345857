import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEvent } from '../models/eventdata';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root'
})
export class EventdataService {

  constructor(private http: HttpClient, private dService: DataService) { }

  getTheEvents(): Promise<IEvent[]> {
    // return this.dService.get("newadmineventform").toPromise();
    return this.dService.get("event/allevents").toPromise();
  }
  getFabLabFieldTripEvents(): Promise<IEvent[]> {
    return this.dService.get("event/FabLabFieldTrips").toPromise();
  }
  GetFabLabSummerCamps(): Promise<IEvent[]> {
    return this.dService.get("event/FabLabSummerCamps").toPromise();
  }
  GetFabLabEvents(): Promise<IEvent[]> {
    return this.dService.get("event/FabLabEvents").toPromise();
  }
}