import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewModule } from './view/view.module';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgxSmoothScrollModule } from '@boatzako/ngx-smooth-scroll';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ViewModule,
    NgxSmoothScrollModule

    
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

function routes(routes: any, arg1: { anchorScrolling: "enabled"; }): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
  throw new Error('Function not implemented.');
}
