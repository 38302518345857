import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { DomSanitizer } from '@angular/platform-browser'
import { Subscription } from 'rxjs';
import { IEvent } from '../../models/eventdata';
import { EventService } from '../../services/event.service';

const provider = new OpenStreetMapProvider();

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  $routeSubscription: Subscription = new Subscription();
  stateParams: any;
  event = new IEvent();
  long: any;
  lat: any;
  mymap: any;
  mymap2: any;
  mapA: any;
  mapB: any;
  mapC: any;
  mapD: any;
  mapE: any;
  mapF: any;
  mapG: any;
  mapH: any;
  mapI: any;
  mapMain: any;
  constructor(private sanitizer: DomSanitizer, public _router: Router, public activeRoute: ActivatedRoute, private eventService: EventService) { }
  ngOnInit(): void {
    provider.search({ query: '2911 Transworld Dr. Stockton, Ca 95206' }).then((result: any) => {
      this.lat = result[0].x;
      this.long = result[0].y;
      this.mapA = '<iframe width="250" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox='
      this.mapB = '\%2C'
      this.mapC = '%2C--'
      this.mapD = '%2C--'
      this.mapE = '&amp;layer=mapnik&amp;marker='
      this.mapF = '" style="border: 1px solid black"></iframe><br/>'
      this.mapMain = this.lat + "%2C" + this.long

      this.mymap = '<iframe width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=' + this.lat + "%2C" + this.long + '%2C' + this.lat + "%2C" + this.long + '&amp;layer=mapnik&amp;marker=' + this.long + "%2C" + this.lat + '" style="border: 1px solid #828282"></iframe><br/>'
      this.mymap2 = '<div class="responsive"><iframe width="350" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=' + this.lat + "%2C" + this.long + '%2C' + this.lat + "%2C" + this.long + '&amp;layer=mapnik&amp;marker=' + this.long + "%2C" + this.lat + '" style="border: 1px solid #828282"></iframe></div><br/>'
      this.mymap = this.sanitizer.bypassSecurityTrustHtml(this.mymap);
      this.mymap2 = this.sanitizer.bypassSecurityTrustHtml(this.mymap2);
    });
  }
}