import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {
  hide3dText = false;
  hideCncText = false;
  hideVinText = false;
  hideRobText = false;
  hideSpaceText = false;
  hideLaserText = false;
  constructor() { }
  toggle3dDisplay() {
    if (this.hide3dText == false){
      this.hide3dText = true;
      let card1 = document.getElementById('card1')!;
      card1.classList.remove('fa-chevron-up');
      card1.classList.add('fa-chevron-down');
    } else if (this.hide3dText == true){
      this.hide3dText = false;
      let card1 = document.getElementById('card1')!;
      card1.classList.remove('fa-chevron-down');
      card1.classList.add('fa-chevron-up');
    }
  }
  toggleCncDisplay() {
    if (this.hideCncText == false){
      this.hideCncText = true;
      let card2 = document.getElementById('card2')!;
      card2.classList.remove('fa-chevron-up');
      card2.classList.add('fa-chevron-down');
    } else if (this.hideCncText == true){
      this.hideCncText = false;
      let card2 = document.getElementById('card2')!;
      card2.classList.remove('fa-chevron-down');
      card2.classList.add('fa-chevron-up');
    }
  }
  toggleVinDisplay() {
    if (this.hideVinText == false){
      this.hideVinText = true;
      let card3 = document.getElementById('card3')!;
      card3.classList.remove('fa-chevron-up');
      card3.classList.add('fa-chevron-down');
    } else if (this.hideVinText == true){
      this.hideVinText = false;
      let card3 = document.getElementById('card3')!;
      card3.classList.remove('fa-chevron-down');
      card3.classList.add('fa-chevron-up');
    }
  }
  toggleRobDisplay() {
    if (this.hideRobText == false){
      this.hideRobText = true;
      let card4 = document.getElementById('card4')!;
      card4.classList.remove('fa-chevron-up');
      card4.classList.add('fa-chevron-down');
    } else if (this.hideRobText == true){
      this.hideRobText = false;
      let card4 = document.getElementById('card4')!;
      card4.classList.remove('fa-chevron-down');
      card4.classList.add('fa-chevron-up');
    }
  }
  toggleSpaceDisplay() {
    if (this.hideSpaceText == false){
      this.hideSpaceText = true;
      let card5 = document.getElementById('card5')!;
      card5.classList.remove('fa-chevron-up');
      card5.classList.add('fa-chevron-down');
    } else if (this.hideSpaceText == true){
      this.hideSpaceText = false;
      let card5 = document.getElementById('card5')!;
      card5.classList.remove('fa-chevron-down');
      card5.classList.add('fa-chevron-up');
    }
  }
  toggleLaserDisplay() {
    if (this.hideLaserText == false){
      this.hideLaserText = true;
      let card6 = document.getElementById('card6')!;
      card6.classList.remove('fa-chevron-up');
      card6.classList.add('fa-chevron-down');
    } else if (this.hideLaserText == true){
      this.hideLaserText = false;
      let card6 = document.getElementById('card6')!;
      card6.classList.remove('fa-chevron-down');
      card6.classList.add('fa-chevron-up');
    }
  }
  ngOnInit(): void {
  }
}
