import { Injectable } from '@angular/core';
import { IEvent } from '../models/eventdata';
import { EventdataService } from '../services/eventdata.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private EventdataService: EventdataService) { }
  
  async getEvent(EventId: number): Promise <IEvent> {
    const events = await this.EventdataService.getTheEvents();
    // console.log(events);
    // console.log("This is the ID: "+EventId);
    // const event = events.find(x => x.EventId == EventId);
    const event = events.find(x => x.eventId == EventId);
    if (event == null) {
      throw new Error(`Cannot find Event with id ${EventId}`)
    }
    return event;
  }
} 